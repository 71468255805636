import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import styles from '../../../Assets/CSS/stylesheet_UHS';
import  Sample from '../../CommonScreens/sampleTextField';
import SampleName from '../../CommonScreens/sampleTextFieldName';
import Fab from "@material-ui/core/Fab";
import { withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import ForumIcon from '@material-ui/icons/Forum';
import CommonTable from "../../CommonScreens/commonTable";
import { Modal } from 'react-bootstrap';
import Loader from '../../loader';
import Header from '../Headers/Header';
import CommonGrid from '../../CommonScreens/CommonGrid';
import UhsGrid from "../../CommonScreens/UhsGrid";
import Configuration from "../../../configurations";
import PbGrid from "../../CommonScreens/pbGrid";
import { Link } from 'react-router-dom';
import Modal1 from '@material-ui/core/Modal';
import Cookies from 'universal-cookie';
import Paper from '@material-ui/core/Paper'
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import i18n from "../../../i18next";
import axios from 'axios';
import configurations from "../../../configurations";
import planConfig from "../../../planConfig";
import ChatButtonNew from '../../CommonScreens/ChatButtonNew';
import "../../../Assets/CSS/common.css";
import MuiPhoneNumber from "material-ui-phone-number";
import "../../../Assets/CSS/common.css";
import { isnoSmartShare } from '../../../clientIDConfig';
import moment from 'moment';
// import QuickQuote3 from './QuickQuote3';

const CrudButton = withStyles(
    styles.crudBtn,
)(Fab);

const ProceedButton = withStyles(
    styles.proceedBtn
)(Button);

const ViewButton = withStyles(
    styles.viewBtn
)(Button);
const ViewButton2 = withStyles(
    customStyle.viewBtn2
)(Button);

const CustomButton = withStyles(
    styles.viewBtn
)(Button);

const StyledTableCell = withStyles(theme => (customStyle.tableCell))(TableCell);
const StyledTableCell1 = withStyles(theme => (customStyle.tableCell1))(TableCell);

const StyledTableRow = withStyles(theme => (customStyle.tableRow))(TableRow);

const CssPhone = withStyles(theme => ({
    root: {
        '& .MuiFormLabel-root': {
            '&:hover:not($disabled):not($focused):not($error):before': {
                borderBottom: '2px solid #533278',
                color: '#533278',
            },
            '&.MuiFilledInput-underline.Mui-focused:after': {
                borderBottom: '2px solid #533278',
                color: '#533278',
            }
        }
    }
}))(MuiPhoneNumber)

function createData1(NAME, UHS1, UHS2, UHS3, UHS4,UHS5,UHS6) {
    return { NAME, UHS1, UHS2, UHS3, UHS4,UHS5,UHS6 };
}

const ACSMrowUhsAll=[
    createData1('One Member per Household	', '$3,000', '$4,500', '$7,500', '$10,500','$15,000','$18,000'),
    createData1('Two Members per Household	', '$6,000', '$9,000','$15,000','$21,000','$30,000','$36,000'),
    createData1('Three or more Members per Household	', '$9,000','$13,500	','$22,500','$31,500','$45,000','$54,000'),
];

const ACSMrowUhs=[
    createData1('One Member per Household	',  '$4,500',  '$10,500','$18,000'),
    createData1('Two Members per Household	',  '$9,000','$21,000','$36,000'),
    createData1('Three or more Members per Household	', '$13,500	','$31,500','$54,000')
];

const cookies = new Cookies();

export default class QuickQuote2 extends Component{
    constructor(props) {
        super(props);
        this.state={
            headerData:[],
            quoteData:[],
            tooltipData:[],
            otherFees:[],
            modalShow:false,
            stateParam: {
                clientId : null
            },
            customerServiceNo : '',
            cid:null,
            STATE_PARAM : JSON.parse(sessionStorage.getItem('STATE_PARAM')), //cookies.get('STATE_PARAM', false),
            isAgent : false,
            ACSMPlanAll:['UHS1','UHS2','UHS3','UHS4','UHS5','UHS6'],
            ACSMPlan:['UHS2','UHS4','UHS6'],
            smartShareModel : false,
            ACSMModal : false,
            mailModal : false,
            email : '',
            msgModal : false,
            errMsg : '',
            confirmationModal : false,
            easyShareModal : false,
            ACSMFlag : false,
            iframeURL : '',
            erollModal : false,
            agentDetails:{},
            prefix:"",
            fname: '',
            middleName:"",
            lname: '',
            email: '',
            suffix:"",
            phone: '',
            prefixSuffix:{},
            countryCode: "+1"
        }
    }

    getQuoteData = (val) => {
        console.log('get-----');
        axios.post(Configuration.baseUrl + '/plan/quickQuote/' + this.props.history.location.state.clientId + "/" + val, this.props.history.location.state.data)
            .then(response=>{
                if(response.data){
                    this.setState({
                        loaderShow : false
                    });
                    response.data.customerServiceNo = sessionStorage.getItem('customerServiceNo');

                    console.log('========= this.props.history.location.search ===========');
                    console.log(this.props.history);
                    response.data.isAgent = this.state.isAgent;

                    this.setState({
                        headerData: response.data.header,
                        quoteData: response.data.quote,
                        tooltipData: response.data.shareableTooltip,
                        otherFees: response.data.otherFees,
                        customerServiceNo: response.data.customerServiceNo,
                        isAgent: response.data.isAgent,
                        program: val
                    })
                }
            })
    }

    componentDidMount() {
        let data={
            "clientId": this.props.history.location.state.clientId
        }
        axios.post(Configuration.baseUrl+'/enrollment/getClient',data)
            .then(response=>{
                if(response.data.response){
                    this.setState({
                        programsList: response.data.response.programList.split(','),
                        association_logo : response.data.response.image,
                        loader : false
                    }, () => {
                        this.state.program = this.state.programsList[0]
                        this.getQuoteData(this.state.programsList[0])
                    });
                }
            });
        window.scrollTo(0, 0);
        let STATE_PARAM = JSON.parse(sessionStorage.getItem('STATE_PARAM')); //cookies.get('STATE_PARAM', false);
        this.state.stateParam.clientId = STATE_PARAM.clientId.toString();
        if(this.props.history.location.pathname === '/quick_quote2'){
            let acsmFlag = false;
            if(this.state.stateParam.clientId === '4350' || this.state.stateParam.clientId === '6548' || this.state.stateParam.clientId === '4367'
            || this.state.stateParam.clientId === '5540' 
            || this.state.stateParam.clientId === '5647' 
            || this.state.stateParam.clientId === '4391' 
            || this.state.stateParam.clientId === '5541' || this.state.stateParam.clientId === '4377'
            || this.state.stateParam.clientId === '5558' || this.state.stateParam.clientId === '4386'
            || this.state.stateParam.clientId === '5696' || this.state.stateParam.clientId === '4397'
            || this.state.stateParam.clientId === '5596' || this.state.stateParam.clientId === '4390'
            ){
                acsmFlag = false
            } else {
                acsmFlag = true
            }
            
            this.setState({
                ACSMFlag : acsmFlag
            })
        } else {
            this.props.history.push("/quick_quote" + this.props.history.location.hash);
        }

        if(STATE_PARAM && STATE_PARAM.brokerId){
            axios.get(configurations.agentURL + '/agentlogin/getAgentById/' + STATE_PARAM.brokerId)
            .then(response => {
                console.log("===RESPONSE===", response.data.response);
                this.setState({
                    agentDetails: response.data.response
                })
            });
        }
    }

    enrollMe = () => {
        sessionStorage.removeItem('CHAT_BOX_Id');
        sessionStorage.removeItem('CLIENT_ID');
        if(this.state.isAgent){
            this.props.history.replace("/");
        } else {
            this.props.history.replace("/signup" + this.props.history.location.hash);
        }
    }

    setModel(){
        let STATE_PARAM = JSON.parse(sessionStorage.getItem('STATE_PARAM'));//cookies.get('STATE_PARAM', false);
        if(STATE_PARAM && STATE_PARAM.clientId){
            this.state.stateParam.clientId = STATE_PARAM.clientId.toString();
        } else {
            this.state.stateParam.clientId = Configuration.clientId.toString();
        }
        let obj = planConfig.find(obj => obj.CLIENT_ID.toString() === this.state.stateParam.clientId.toString())

        this.setState({
            iframeURL : obj.iframeURL,
            modalShow : true,
            loaderShow : true
        })
    }

    handleBack = () =>{
        sessionStorage.removeItem('CHAT_BOX_Id');
        this.props.history.replace("/quick_quote"+this.props.history.location.hash);
    }

    handleSentMail = (val,itemValid,parentDetails) =>{
        
        if(this.state.isAgent && this.state.STATE_PARAM.user_subId ){
            this.setState({
                email : this.state.STATE_PARAM.user_subId,
                confirmationModal : true
            });
        } else {
            this.setState({
                mailModal : true,
                email : ''
            });
        }
    }

    handleEnrollModal = () =>{
        if(this.state.isAgent){
            sessionStorage.removeItem('CHAT_BOX_Id');
            sessionStorage.removeItem('CLIENT_ID');
            this.props.history.replace("/");
        } else {
            this.setState({  
                erollModal: true,
            });
        }
    }

    getValue = (val, iteamValid, parentDet) =>{
        if(iteamValid){
            this.state.email = val
        } else {
            this.state.email = '';
        }
        this.setState({
            refresh : true
        });
    }

    sendQuoteMail = () =>{
        this.setState({
            loaderShow : true,
            mailModal : false,
            confirmationModal : false
        });

        let QUOTE_EMAIL = JSON.parse(sessionStorage.getItem('QUOTE_EMAIL'));
        axios.post(configurations.baseUrl + '/plan/sendQuickQuoteMail/'+ this.state.email +'/' + this.state.STATE_PARAM.clientId  +'/' + this.state.STATE_PARAM.brokerId + '/' + this.state.isAgent + '/' + this.state.program.replace("UHF", "UHS") , QUOTE_EMAIL)
            .then(response => {
                if (response.data.code === 200) {
                    this.setState({
                        msgModal: true,
                        loaderShow: false,
                        errMsg: 'Quote email sent successfully!',
                        email : ''
                    });
                } else {
                    this.setState({
                        msgModal: true,
                        loaderShow: false,
                        errMsg: 'Internal Server Error',
                        email : ''
                    });
                }
            });
    }

    setUserValue(value, isValid, parentDetails) {
        console.log('-----  ----- ', value, ' - - ', isValid, ' - - - - ', this.state.phone);
        if (parentDetails.name === 'firstname') {
            if (isValid) {
                this.state.fname = value;
            } else {
                this.state.fname = "";
            }
        }
        if (parentDetails.name === 'lastname') {
            if (isValid) {
                this.state.lname = value;
            } else {
                this.state.lname = "";
            }
        }
        if (parentDetails.name === 'email') {
            if (isValid) {
                this.state.userEmail = value;
            } else {
                this.state.userEmail = "";
            }
        }
        this.checkFormValidation()
    }
    setUserValuePop = (value, isValid, { name }) => {
        const fieldMap = {
            'firstname': 'fname',
            'prefix': 'prefix',
            'middleName': 'middleName',
            'lastname': 'lname',
            'suffix': 'suffix',
            'email': 'email',
            'phone': 'phone'
        };
    
        if (name === 'prefix' || name === 'suffix' || name === 'middleName') {
            this.state.prefixSuffix[name] = isValid;
            this.state[name] = isValid ? value.toUpperCase() : "";
        } else if (name === 'email') {
            this.state[fieldMap[name]] = isValid ? value : "";
        } else if (fieldMap[name]) {
            this.state[fieldMap[name]] = isValid ? value.toUpperCase() : "";
        }
    
        this.checkVal();
    };

    checkValidation(){
        if(this.state.coverage === 'me'){
            if(this.state.yourDetails.age !== '' && this.state.yourDetails.gender !== '' && this.state.state !== ''){
                this.setState({validDetails:true});
            } else {
                this.setState({validDetails:false});
            }
        } else if(this.state.coverage === 'spouse'){
            if(this.state.yourDetails.age !== '' && this.state.yourDetails.gender !== '' && this.state.state !== '' && this.state.spouse.age !== '' && this.state.spouse.gender !== ''){
                this.setState({validDetails:true});
            } else {
                this.setState({validDetails:false});
            }
        } else if(this.state.coverage === 'family'){
            let count =0;
            for(let i=0;i<this.state.family.length;i++){
                if((this.state.coverage === 'child' || this.state.coverage === 'family') && !this.state.family[i].valid){
                    count++;
                }                
            }

            if(this.state.yourDetails.age !== '' && this.state.yourDetails.gender !== '' && this.state.state !== '' && this.state.spouse.age !== '' && this.state.spouse.gender !== '' && count === 0){
                this.setState({validDetails:true});
            } else {
                this.setState({validDetails:false});
            }
        } else if(this.state.coverage === 'child'){
            let count =0;
            for(let i=0;i<this.state.family.length;i++){
                if((this.state.coverage === 'child' || this.state.coverage === 'family') && !this.state.family[i].valid){
                    count++;
                }
            }

            if(this.state.yourDetails.age !== '' && this.state.yourDetails.gender !== '' && this.state.state !== '' && count === 0){
                this.setState({validDetails:true});
            } else {
                this.setState({validDetails:false});
            }
        }

    }

    submitData = () => {
        let newPhone = this.state.phone.replace(/\D+/g, "")
        this.setState({
            loaderShow: true
        })
        let userdata = []
        let userObj
        userdata.push(JSON.parse(sessionStorage.getItem("Details")))
        userObj = {
                "email": this.state.userEmail,
                "phone": this.state.phone ? newPhone.slice(1) : '',
                "zipcode": this.state.zipCode,
                "sharingfor": "me",
                "ipaddress": sessionStorage.getItem('PUBLIC-IP'),
                // "age": val.age,
                // "startAmount": this.props.minAmount,
                // "endAmount": this.props.maxAmount
            }
        let obj1
        if (this.state.STATE_PARAM.empid) {
            obj1 = JSON.stringify({
                "email": this.state.userEmail,
                // "empid": this.state.STATE_PARAM.empid,
                "first_name": this.state.fname.trim(),
                "last_name": this.state.lname.trim(),
                "phone": this.state.phone ? newPhone.slice(1) : '',
                "client_id": String(this.state.STATE_PARAM.clientId),
                "association_id": this.state.STATE_PARAM.associationId,
                "agent_id": this.state.STATE_PARAM.brokerId,
                "empid": this.state.STATE_PARAM.empid ? this.state.STATE_PARAM.empid : 'NULL',
                "source": "Website",
                "leadSource": "Campaign/Channel",
                "website_channelc": this.state.STATE_PARAM.brokerId + "/" + this.state.STATE_PARAM.channelName,
                "qualification_params": [
                    {
                        "City": this.props.history.location.state.city,
                        "State": this.props.history.location.state.state,
                        "PostalCode": this.props.history.location.state.zipcode
                    }
                ],
                "member_type":"UHF"
            })
        } else {
            obj1 = JSON.stringify({
                "email": this.state.userEmail,
                // "empid": this.state.STATE_PARAM.empid,
                "first_name": this.state.fname.trim(),
                "last_name": this.state.lname.trim(),
                "phone": this.state.phone ? newPhone.slice(1) : '',
                "client_id": String(this.state.STATE_PARAM.clientId),
                "association_id": this.state.STATE_PARAM.associationId,
                "agent_id": this.state.STATE_PARAM.brokerId,
                "source": "Website",
                "leadSource": "Campaign/Channel",
                "website_channelc": this.state.STATE_PARAM.brokerId + "/" + this.state.STATE_PARAM.channelName,
                "qualification_params": [
                    {
                        "City": this.props.history.location.state.city,
                        "State": this.props.history.location.state.state,
                        "PostalCode": this.props.history.location.state.zipcode
                    }
                ],
                "member_type":"UHF"
            })
        }
        axios.post(Configuration.baseUrl + '/enrollment/addRadioData', JSON.parse(JSON.stringify(userObj)))
            .then(response => {
                if (response.data.code === 200) {
                    this.setState({
                    });
                } else if (response.data.code === 202) {
                    this.setState({
                        loaderShow: false,
                        msgModal: true,
                        errMsg: "Data already registered!"
                    });
                }
                else {
                    this.setState({
                        loaderShow: false,
                        msgModal: true,
                        errMsg: "Internal server error"
                    })
                }
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                this.setState({
                    loaderShow: false,
                    msgModal: true,
                    errMsg: "Something bad happened"
                });
            });

        var config = {
            method: 'post',
            url: Configuration.tokenURL,
            auth: {
                username: configurations.token_username,
                password: configurations.token_password
            },
            headers: {
                'x-api-key': configurations.tiken_x_api_key,
            }
        };
        axios(config)
            .then(response => {
                if (response && response.data) {
                    console.log("token---", response)
                    this.setState({
                        accessToken: response.data.access_token,
                        // loader: false,
                    }, () => {
                        var configLead = {
                            method: 'post',
                            url: Configuration.leadGenrateURL,
                            headers: {
                                'Authorization': this.state.accessToken,
                                'x-api-key': configurations.lead_generation_x_api_key,
                                'Content-Type': 'application/json'
                            },
                            data: obj1
                        };
                        axios(configLead)
                            .then(response => {
                                if (response && response.status == 200 && response.data && response.data.code === 200) {
                                    this.setState({
                                        msgModal: true,
                                        loaderShow: false,
                                        errMsg: "Thank you for sharing your contact information. Our representative will get in touch with you shortly.",
                                    })
                                }
                                if (response && response.data && response.data.code === 202) {
                                    this.setState({
                                        loaderShow: false,
                                        msgModal: true,
                                        errMsg: "Email already registered!",
                                    })
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                console.log(error.response);
                                this.setState({
                                    loaderShow: false,
                                    msgModal: true,
                                    errMsg: "Something bad happened"
                                });
                            });
                    })
                }
            })
            this.closeCuram();
    }
    closeCuram = () => {
        this.setState({
            showQuickQuoteModal: false,
            userEmail: '',
            fname: '',
            lname: '',
            phone: '',
        })
    }

    checkFormValidation() {
        if (this.props.fromAgent) {
            if (this.state.userEmail !== '' && this.state.fname !== '' && this.state.lname !== '') {
                this.setState({ disableSubmit: false });
            } else {
                this.setState({ disableSubmit: true });
            }
        } else {
            if (this.state.userEmail !== '' && this.state.fname !== '' && this.state.lname !== '') {
                this.setState({ disableSubmit: false });
            } else {
                this.setState({ disableSubmit: true });
            }
        }

    }
    setUserValue = (value, isValid, { name }) => {
        const fieldMap = {
            'firstname': 'fname',
            'prefix': 'prefix',
            'middleName': 'middleName',
            'lastname': 'lname',
            'suffix': 'suffix',
            'email': 'email',
            'phone': 'phone'
        };
    
        if (name === 'prefix' || name === 'suffix' || name === 'middleName') {
            this.state.prefixSuffix[name] = isValid;
            this.state[name] = isValid ? value.toUpperCase() : "";
        } else if (name === 'email') {
            this.state[fieldMap[name]] = isValid ? value : "";
        } else if (fieldMap[name]) {
            this.state[fieldMap[name]] = isValid ? value.toUpperCase() : "";
        }
    
        this.checkVal();
    };
    checkVal1(flag) {
        if (flag === 'ENCODING') {
            if (this.state.cid !== '' && this.state.bid !== '' && this.state.aid !== '' && this.state.baseUrl !== '') {
                this.setState({ genrate: false })
            } else {
                this.setState({ genrate: true })
            }
        } else if (flag === 'USER') {
            if (this.state.fname !== '' && this.state.lname !== '' && this.state.email !== '' && this.state.phone !== '' && this.state.phone.length == 10 && this.state.prefix !== '') {
                this.setState({ userValid: false })
            } else {
                this.setState({ userValid: true })
            }
        }
    }
    checkVal() {
        if(sessionStorage.getItem('EMPLOYER_FLOW') === "YES"){
            if ( this.state.fname !== '' && this.state.lname !== '' && this.state.prefix !== "") {
                this.setState({ userValid: false })
            } else {
                this.setState({ userValid: true })
            }
        }else{
            if ( this.state.fname !== '' && this.state.lname !== '' && this.state.email !== ''&& this.state.phone !== '' && this.state.phone.length == 17) {
                if(this.state.prefixSuffix.prefix === false || this.state.prefixSuffix.suffix === false || this.state.prefixSuffix.middleName === false ){
                    this.setState({ userValid: true })
                }else{
                    this.setState({ userValid: false })
                }
            } else {
                this.setState({ userValid: true })
            }
        }
       
    }
    addUserModal = () => {
        this.setState({
            addUsermodalShow: true,
            fname: '',
            lname: '',
            phone: '',
            middleName:'',
            prefix:'',
            suffix:'',
            email: '',
            refresh: false
        }, () => this.checkVal1('USER'));
    }
    addUser = () => {
        let STATE_PARAM = JSON.parse(sessionStorage.getItem("STATE_PARAM"))
        let newPhone = this.state.phone.replace(/[^a-zA-Z0-9]/g, '');
        this.setState({
            loaderShow: true,
            addUsermodalShow: false
        });
        let obj={}
        if(sessionStorage.getItem('EMPLOYER_FLOW') === "YES"){

            obj = {
                "firstName": this.state.fname.trim(),
                "lastName": this.state.lname.trim(),
                "phone":this.state.phone? this.state.countryCode + newPhone.substr(1) :null,
                "email": this.state.email?this.state.email:null,
                "clientId": STATE_PARAM.clientId,
                "associationId": STATE_PARAM.associationId,
                "brokerId": STATE_PARAM.brokerId,
                "empid": STATE_PARAM.empid,
                "prefix": this.state.prefix,
                "suffix": this.state.suffix,
                "middleName": this.state.middleName,
            };

            if(this.state.email !== ''){

                axios.get(configurations.baseUrl + "/agentlogin/validateEmail/" + this.state.email)
                .then((response) => {
                    console.log("validateemail===", response)
                    if (response.data.code == 200) {
                        this.addUserObj(obj);
                    } 
                    // else if(response.data.code == 500){
                    //     this.setState({
                    //         loaderShow: false,
                    //         showCommonErrorModal: true,
                    //     });
                    // }
                    else {
                        this.setState({
                            loaderShow: false,
                            msgModal: true,
                            errMsg: "Employee already registered with this Email ID!",
                            refresh: true
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    // if (error.response.status === 500) {
                        
                        this.setState({
                            loaderShow: false,
                            // showCommonErrorModal: true,
                        });
                    // }
                });
                
            }else{
                this.addUserObj(obj);
            }
            

        }else{ // For Household
            obj = {
                "firstName": this.state.fname.trim(),
                "lastName": this.state.lname.trim(),
                "phone":this.state.countryCode + newPhone.substr(1),
                "email": this.state.email,
                "clientId": STATE_PARAM.clientId,
                "associationId": STATE_PARAM.associationId,
                "brokerId": STATE_PARAM.brokerId,
                "empid": STATE_PARAM.empid,
                "prefix": this.state.prefix,
                "suffix": this.state.suffix,
                "middleName": this.state.middleName,
            };

            if(this.state.email !== ''){

                axios.get(configurations.baseEnrollUrl + "/agentlogin/validateEmail/" + this.state.email)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.addUserObj(obj);
                    }              
                    else {
                        this.setState({
                            loaderShow: false,
                            msgModal: true,
                            errMsg: "Prospect already registered!",
                            refresh: true
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    // if (error.response.status === 500) {
                        
                        this.setState({
                            loaderShow: false,
                            // showCommonErrorModal: true,
                        });
                    // }
                });
                
            }else{
                this.addUserObj(obj);
            }
        }
        
    }
    addUserObj = (obj) => {
        let STATE_PARAM = JSON.parse(sessionStorage.getItem("STATE_PARAM"))
        axios.post(configurations.baseEnrollUrl + '/agentlogin/addUser', JSON.parse(JSON.stringify(obj)))
            .then(response => {
              
                if (response.data.code === 200) {
                    let objectofEncypt ={
                        "clientId": STATE_PARAM.clientId,
                        "channelName": STATE_PARAM.channelName,
                        "associationId": STATE_PARAM.associationId,
                        "brokerId": STATE_PARAM.brokerId,
                        "isAgent": true,
                        "user_subId": obj.email,
                        "index": 4,
                        "empid": STATE_PARAM.empid,
                        "memberId": response.data.response.id,
                        "subID": response.data.response.subId,
                        "reEnrolledByAgent": false
                    }
                    axios.post(configurations.baseEnrollUrl + '/encrypt/encryptData', objectofEncypt).then(resp =>{
                        let url = configurations.enrollmentURL + '/login#state=' + resp.data.response;
                        sessionStorage.clear();
                        localStorage.clear();                     
                        window.location.href =url; 
                    })
                   
                } else if (response.data.code === 202) {
                    this.setState({
                        loaderShow: false,
                        msgModal: true,
                        errMsg: sessionStorage.getItem('EMPLOYER_FLOW') === "YES"? "Employee already registered with this Email ID!":"Prospect already registered!",
                        refresh: true
                    });
                }
                else {
                    this.setState({
                        loaderShow: false,
                        msgModal: true,
                        errMsg: "Internal server error",
                        refresh: true
                    })
                }
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                // if (error.response.status === 500) {
                    
                    this.setState({
                        loaderShow: false,
                        // showCommonErrorModal: true,
                    });
                // }
            });
    }
    render() {
    
        console.log(this.state.stateParam.clientId);
       
        return(
            <div>
                 <Header></Header>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
              

                            <div style={{marginTop: '30px', paddingRight : '21px', paddingLeft: '21px', paddingBottom: '21px'}}>{/* style={styles.ComponentContainer} */}
                                <div style={{width:'100%', backgroundColor:'#ffffff',paddingLeft:'10px'}}>
                                    
                                    <Grid xs={12} style={styles.QuickQt2TopWrp} item={true}>
                                        <span style={styles.QuickQtTopRightText1}>Your quick quote!</span>
                                        <span style={styles.QuickQtTopRightText2}>
                                        Here is a provisional quote with benefits and  monthly contributions across programs listed. Your contributions may changes based on your family's current health status.

                                </span>
                                    </Grid>


                                    <Grid container >
                    <Grid xs={12} style={{paddingLeft:'10px',paddingRight:'25px',tableLayout:'fixed',paddingBottom:'15px'}} item={true}>
                    <div style={{ overflowX: "auto" }} >
                        <CommonTable getQuote={(val) => this.getQuoteData(val)} program={this.state.program} programsList={this.state.programsList} quoteData={this.state.quoteData} check={true} headerData={this.state.headerData} tooltip={this.state.tooltipData} quickQuote={true} totalReq={true} ACSM={this.state.ACSMFlag} clientId={this.state.stateParam.clientId} />
                    </div>
                    </Grid>
                    

                    </Grid>




                    <Grid container justify='space-between'>
                        <Grid item xs={12} sm={7} style={{marginLeft:'1%',marginBottom:'10px',paddingRight:'17px',textAlign:'justify'}}>
                        <span style={styles.QuickQt2EnrollText}>
                        To join, simply click the&nbsp;
                        <span style={{fontWeight:'bold'}}>ENROLL</span> button below and follow the easy, prompted registration process. There you can review the available sharing plans, monthly contribution levels, and sharing plans highlights to choose the one that works best for you and your family. You can also call 800.921.4505 and register with one of our trusted coaches who will walk you through the process.
                        
                       

                        </span>

                       
                        </Grid>

                        <Grid item xs={12} sm={4} style={{paddingRight:'26px'}}>
                        <span style={styles.QuickQt2FeesTxt}>
                        Other applicable fees across all programs
                        </span>
                        {
                                this.state.otherFees.length > 0 && this.state.otherFees.map((key,index)=>{
                                    return(
                                        <div style={styles.QuickQt2FeesChild1Wrp1Mob} key={index}>
                                            <span style={styles.QuickQt2FeesTxt1}>{key.key}</span>
                                            <span style={styles.QuickQt2FeesTxt2}>{key.value}</span>
                                        </div>
                                    )
                                })
                            }
                        </Grid>

                    </Grid>
                                    <Grid container alignItems="stretch" style={{marginTop:'1%'}}>
                                        {
                                            (this.state.STATE_PARAM.clientId.toString() !== '6548' 
                                            && this.state.STATE_PARAM.clientId.toString() !== '4367' 
                                            && this.state.STATE_PARAM.clientId.toString() !== '5540' 
                                            && this.state.STATE_PARAM.clientId.toString() !== '5541' 
                                            && this.state.STATE_PARAM.clientId.toString() !== '4377'
                                            && this.state.STATE_PARAM.clientId.toString() !== '5558'
                                            && this.state.STATE_PARAM.clientId.toString() !== '4386'
                                            && this.state.STATE_PARAM.clientId.toString() !== '5696'
                                            && this.state.STATE_PARAM.clientId.toString() !== '4397'
                                            && this.state.STATE_PARAM.clientId.toString() !== '5596'
                                            && this.state.STATE_PARAM.clientId.toString() !== '4390'
                                            ) ?
                                                <Grid item xs={12} md={12}>
                                                    <div style={{display: 'flex'}}>
                                                    {(this.state.STATE_PARAM.clientId.toString() == '5742' || this.state.STATE_PARAM.clientId.toString() == '4599') &&
                                                <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}}>
                                                            <ViewButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={()=>this.setModel()}
                                                                tyle={{marginRight: '20px',marginBottom:'10px'}}
                                                                >Senior 65 Program Brochure</ViewButton>
                                                        </Grid>}
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}} hidden={this.state.program != "UHF"}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={()=>this.setModel()}
                                                            style={{marginRight: '20px',marginBottom:'10px'}}
                                                        >View All UHS Standard Programs</ViewButton>
                                                    </Grid>
                                                    {(!isnoSmartShare(this.state.STATE_PARAM.clientId.toString()) || this.state.program != "UHF") &&
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}} hidden={this.state.program != "UHF" || this.state.STATE_PARAM.clientId.toString() === "5647" || this.state.STATE_PARAM.clientId.toString() === "4391"}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            hidden={this.state.STATE_PARAM.clientId.toString() === "4350" || this.state.STATE_PARAM.clientId.toString() === "5689" || this.state.STATE_PARAM.clientId.toString() === "4396" || this.state.STATE_PARAM.clientId.toString() === "5702" || this.state.STATE_PARAM.clientId.toString() === "4399"}
                                                            onClick={() => {this.setState({smartShareModel : true, loaderShow : true})}}
                                                            style={{width: '100%',marginRight: '20px',marginBottom:'10px'}}
                                                        >View UHS SmartShare Programs</ViewButton>
                                                    </Grid>
                                                    }
                                                    </div>
                                                    <div style={{display: 'flex'}}>
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}} hidden={this.state.STATE_PARAM.clientId.toString() === "4350" || this.state.program != "UHF" || this.state.STATE_PARAM.clientId.toString() === "5647" || this.state.STATE_PARAM.clientId.toString() === "4391"}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {this.setState({ACSMModal : true})}}
                                                            style={{width: '100%',marginRight: '20px',marginBottom:'10px'}}
                                                        >ACSM value add-on for UHS Standard programs</ViewButton>
                                                    </Grid>
                                                    {this.state.program === "THS" &&
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            className="thriveButton"
                                                            style={{width: '100%',marginRight: '20px',marginBottom:'10px'}}
                                                            hidden={this.state.program !== "THS"}
                                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-HealthShare-Program-Grid.pdf')}>
                                                            VIEW All Thrive PROGRAMS
                                                        </ViewButton>
                                                    </Grid>   
                                                    }                                      
                                                    {this.state.program == "Prosper" && 
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            className="thriveButton"
                                                            style={{width: '100%',marginRight: '20px',marginBottom:'10px'}}
                                                            hidden={this.state.program == "UHF" || this.state.program !== "Prosper"}
                                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Prosper-Program-Grid.pdf')}>
                                                            View all Prosper Programs
                                                        </ViewButton>
                                                    </Grid>}
                                                    <Grid item xs={10} sm={12} md={3} lg={3} style={{marginLeft:'1%'}}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            className="thriveButton"
                                                            style={{width: '100%',marginRight: '20px',marginBottom:'10px'}}
                                                            hidden={this.state.program !== "STX"}
                                                            onClick={() => window.open('https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-STx-Program-Grid.pdf')}>
                                                            View all ST<sub>x</sub>&nbsp;Programs
                                                        </ViewButton>
                                                    </Grid>
                                            
                                                
                                                    </div>
                                                </Grid>
                                               
                                            :
												 (this.state.STATE_PARAM.clientId.toString() !== '5541' && this.state.STATE_PARAM.clientId.toString() !== '4377' && this.state.STATE_PARAM.clientId.toString() !== '5696' && this.state.STATE_PARAM.clientId.toString() !== '4397' && this.state.STATE_PARAM.clientId.toString() !== '5558' && this.state.STATE_PARAM.clientId.toString() !== '4386' && this.state.STATE_PARAM.clientId.toString() !== '5596' && this.state.STATE_PARAM.clientId.toString() !== '4390')?
                                                <Grid item xs={12} md={12}>
                                                    <Grid item xs={10} sm={12} md={2} lg={2} style={{marginLeft:'1%', display : 'inline-block'}}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={()=>this.setModel()}
                                                            style={{width: '100%', marginRight: '20px',marginBottom:'10px'}}
                                                        >View All Healthy Life(HL) Programs</ViewButton>
                                                    </Grid>
                                                    <Grid item xs={10} sm={12} md={4} lg={4} style={{marginLeft:'1%', display : 'inline-block'}}>
                                                        <ViewButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => { this.setState({ easyShareModal: true, loaderShow: true }) }}
                                                            style={{ width: '100%', marginRight: '20px', marginBottom: '10px' }}
                                                        >View EasyShare(ES) Programs</ViewButton>
                                                    </Grid>
                                                </Grid>
												:
                                                (this.state.STATE_PARAM.clientId.toString() === '5696' || this.state.STATE_PARAM.clientId.toString() === '4397' || this.state.STATE_PARAM.clientId.toString() === '5558' || this.state.STATE_PARAM.clientId.toString() === '4386' || this.state.STATE_PARAM.clientId.toString() === '4390'|| this.state.STATE_PARAM.clientId.toString() === '5596') ?
                                    <Grid item xs={12} md={12} style={{display: 'flex'}}>
                                        <Grid item xs={10} sm={12} md={2} lg={2} style={{marginLeft:'1%'}}>
                                            <ViewButton
                                                variant="contained"
                                                color="primary"
                                                onClick={()=>this.setModel()}
                                                style={{width: '100%'}}
                                            >View All Healthy Life (HL) Programs</ViewButton>
                                        </Grid>
                                        
                                        {(this.state.STATE_PARAM.clientId.toString() === '4390' || this.state.STATE_PARAM.clientId.toString() === '5596') &&
                                            <Grid item xs={10} sm={12} md={2} lg={2} style={{ marginLeft: '1%' }} hidden={this.state.program == "THS" || this.state.STATE_PARAM.clientId.toString() === "5647"}>
                                                <ViewButton
                                                    variant="contained"
                                                    color="primary"
                                                    hidden={this.state.STATE_PARAM.clientId.toString() === "4350"}
                                                    onClick={() => { this.setState({ smartShareModel: true, loaderShow: true }) }}
                                                    style={{ width: '100%', marginRight: '20px', marginBottom: '10px' }}
                                                >View UHS SmartShare Programs</ViewButton>
                                            </Grid>
                                        }
                                        
                                    </Grid>
                                        
                                            :
												<Grid item xs={12} md={12}>
                                                <Grid item xs={10} sm={12} md={2} lg={2} style={{marginLeft:'1%', display : 'inline-block'}}>
                                                    <ViewButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={()=>this.setModel()}
                                                        style={{width: '100%', marginRight: '20px',marginBottom:'10px'}}
                                                    >View all Community HealthShare Programs</ViewButton>
                                                </Grid>
                                               
                                            </Grid>
                                        }
                                        
                                    </Grid>
                    <Grid container style={{marginTop:'2%'}}>
                        <Grid item xs={10} sm={12} md={2} lg={2} >
                        <ProceedButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSentMail}
                                    style={{width: '100%', height: '40px',marginBottom:'10px',borderRadius:'30px'}}
                                >SEND QUOTE</ProceedButton>
                        </Grid>
                    <Grid item xs={10} sm={12} md={2} lg={2} style={{marginLeft:'1%', display : 'inline-block'}}>
                                <ProceedButton
                                    variant="contained"
                                    color="primary"
                                    onClick={sessionStorage.getItem("agentLaunch") && sessionStorage.getItem('EMPLOYER_FLOW') !== "YES"  ? this.addUserModal :this.handleEnrollModal}
                                    style={{width: '100%', height: '35px',marginBottom:'10px',borderRadius:'30px'}}
                                ><strong>{this.state.isAgent ? "ENROLL" : "ENROLL"}</strong></ProceedButton>
                        </Grid>
                        <Grid item xs={12} sm={5} hidden={this.state.isAgent}>
                    </Grid>

                    </Grid>


                    <Modal size="lg" show={this.state.addUsermodalShow} onHide={(event) => this.setState({ addUsermodalShow: false, loaderShow: false })} centered backdrop='static'>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        {
                            sessionStorage.getItem('EMPLOYER_FLOW') === "YES" ?
                                <Modal.Title>Add Employee</Modal.Title>
                                :
                                <Modal.Title>Add Prospect</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body >
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        <div style={{ flexGrow: 1 }}>
                            <Grid container spacing={2} justify="center" >
                                <Grid item xs={12} sm={2} >
                                    <SampleName setChild={this.setUserValuePop.bind(this)} name={'prefix'} label={'Prefix'} value={this.state.prefix} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'noSpaceName'} errMsg={'Enter valid prefix'} parentDetails={{ name: 'prefix' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <SampleName setChild={this.setUserValuePop.bind(this)} reqFlag={true} name={'firstname'} label={'First Name'} value={this.state.fname} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'firstname' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <SampleName setChild={this.setUserValuePop.bind(this)} name={'middleName'} label={'Middle Name'} value={this.state.middleName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'noSpaceName'} errMsg={'Enter valid Middle Name'} parentDetails={{ name: 'middleName' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <SampleName setChild={this.setUserValuePop.bind(this)} reqFlag={true} name={'lastname'} label={'Last Name'} value={this.state.lname} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'fullName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'lastname' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={2} >
                                    <SampleName setChild={this.setUserValuePop.bind(this)} name={'suffix'} label={'Suffix'} value={this.state.suffix} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'noSpaceName'} errMsg={'Enter valid suffix'} parentDetails={{ name: 'suffix' }}></SampleName>
                                </Grid>
                                {sessionStorage.getItem('EMPLOYER_FLOW') === "YES" ?
                                <>
                                <Grid item xs={12} sm={5} >
                                    <Sample setChild={this.setUserValuePop.bind(this)} reqFlag={false} name={'email'} label={'Email ID'} value={this.state.email} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid email Id'} parentDetails={{ name: 'email' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <div>
                                        <MuiPhoneNumber
                                            name={"phone"}
                                            id="filled-required"
                                            label={"Mobile No."}
                                            data-cy="user-phone"
                                            variant="filled"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoComplete='off'
                                            value={this.state.phone}
                                            length={17}
                                        
                                            onChange={(e) => this.setUserValue(e, true, { name: 'phone' })}
                                            disable={false} style={customStyle.textFieldWrpAgent} fieldType={'phone'}
                                            errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                        />
                                        {this.state.phone.length !== 17 && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required mobileErr">Enter valid mobile no.</p>}
                                        {/* <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'phone'} label={'Mobile No.'} value={this.state.phone} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'phone'} errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'} parentDetails={{ name: 'phone' }}></Sample> */}
                                    </div>
                                </Grid>
                                </>
                                :
                                <>
                                <Grid item xs={12} sm={5} >
                                    <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'email'} label={'Email ID'} value={this.state.email} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid email Id'} helperMsg={"Email is required"} parentDetails={{ name: 'email' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5} >
                                    <div>
                                        <MuiPhoneNumber
                                            name={"phone"}
                                            required id="filled-required"
                                            label={"Mobile No."}
                                            data-cy="user-phone"
                                            variant="filled"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoComplete='off'
                                            value={this.state.phone}
                                            length={17}
                                            onChange={(e) => this.setUserValue(e, true, { name: 'phone' })}
                                            disable={false} style={customStyle.textFieldWrpAgent} fieldType={'phone'}
                                            errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                        />
                                        {this.state.phone.length !== 17 && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required mobileErr">Enter valid mobile no.</p>}
                                    </div>
                                </Grid>
                                </>
                            }
                                
                            </Grid>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ margin: '0', marginRight: '10px', width: '95px', height: '40px' }} disabled={this.state.userValid} onClick={() => this.addUser()}>ADD</CustomButton>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ addUsermodalShow: false, loaderShow: false })}>Cancel</CustomButton>
                    </Modal.Footer>
                </Modal>
                    
                        <Modal size="xl" show={this.state.modalShow} centered onHide={(event) => this.setState({modalShow:false,loaderShow : false})}>
                            <Modal.Header style={styles.modal_header} closeButton>
                                <Modal.Title>View Programs</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ padding: '0' }}>
                                {
                                    this.state.loaderShow ? <Loader></Loader> : ''
                                }
                                {/*<iframe style={{ height: '450px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={"http://localhost:3000/?clientId=" + this.state.stateParam.clientId +"&checkboxReq=false"} />*/}

                                {
                                    <iframe style={{ height: '430px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={this.state.iframeURL}></iframe>
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <CustomButton onClick={() => this.setState({modalShow:false, loaderShow : false})}>Done</CustomButton>
                            </Modal.Footer>
                        </Modal>

                                    {/* --------------------------------ACSM Modal ------------------------------------------------- */}

                                    <Modal size="lg" show={this.state.ACSMModal}  onHide={() => {this.setState({ACSMModal : false})}} backdrop="static">
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>Annual Co-Share Maximum for UHS Standard Programs Only</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '15px' ,textAlign : 'justify', overflowX : 'hidden', fontfamily: 'Roboto, Arial, Helvetica, sans-serif'}}>
                                            {
                                                this.state.loaderShow ? <Loader></Loader> : ''
                                            }
                                            <span style={{fontSize:'13px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>If you choose this option, 100% of your Eligible Medical Expenses become sharable when your Annual Co-Share Maximum (ACSM)
                                               has been met. The ACSM differs for each program. Also, the Preventive Care is increased to $1,000 per member per year
                                               (subject to a 90-day waiting period). Check the box for the ACSM option and you can view the difference in monthly contribution
                                               amounts for each program.
                                            </span>

                                            <Table aria-label="customized table1"  style={customStyle.tableACSM}>
                                                <TableHead style={{backgroundColor:'#420045',position: 'sticky'}}>
                                                    <TableRow style={{position: 'sticky'}}>
                                                        <StyledTableCell1 padding='checkbox' align='center' style={customStyle.tableHead1ACSM} >
                                                            Program Name
                                                        </StyledTableCell1>
                                                        {
                                                            (this.state.headerData.length - 1 === 7 || isnoSmartShare(this.state.STATE_PARAM.clientId.toString())) ?
                                                                this.state.ACSMPlanAll.map((option, index) => (
                                                                    <StyledTableCell1 style={customStyle.tableHead2ACSM}  align='center' key={index} value={option.id}>
                                                                        {option}
                                                                    </StyledTableCell1>

                                                                )) :
                                                                this.state.ACSMPlan.map((option, index) => (
                                                                    <StyledTableCell1 style={customStyle.tableHead2ACSM}  align='center' key={index} value={option.id}>
                                                                        {option}
                                                                    </StyledTableCell1>

                                                                ))

                                                        }
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody >
                                                    <StyledTableRow align='center' style={customStyle.rowHead} >
                                                        <StyledTableCell1  align="center" style={customStyle.tableRowHeadACSM} >
                                                        <text style={{color: '#fff'}}>Annual Out-of-Pocket</text>
                                                        </StyledTableCell1>
                                                        <StyledTableCell1  align="center" colSpan={6} style={customStyle.tableRowHead2ACSM} >
                                                        <text style={{color: '#fff'}}>Annual Co-Share Maximum Amounts</text>
                                                        </StyledTableCell1>
                                                    </StyledTableRow>
                                                    {
                                                        (this.state.headerData.length - 1 === 7 || isnoSmartShare(this.state.STATE_PARAM.clientId.toString())) ?
                                                            ACSMrowUhsAll.map((row,index) => (

                                                                <StyledTableRow align="left" style={{backgroundColor:'rgb(234, 232, 219)',border : '2px solid #ffffff'}} key={index}>
                                                                    <StyledTableCell component="th" scope="row" style={customStyle.cellTitle}>
                                                                        {row.NAME}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild}  >{row.UHS1}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS2}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild}>{row.UHS3}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS4}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS5}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS6}</StyledTableCell>
                                                                </StyledTableRow>
                                                            )) :
                                                            ACSMrowUhs.map((row,index) => (

                                                                <StyledTableRow align="center" style={{backgroundColor:'rgb(234, 232, 219)',border : '2px solid #ffffff'}} key={index}>
                                                                    <StyledTableCell component="th" scope="row" style={customStyle.cellTitle}>{row.NAME}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS1}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS2}</StyledTableCell>
                                                                    <StyledTableCell align="center" style={customStyle.cellChild} >{row.UHS3}</StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                    }


                                                </TableBody>

                                            </Table>
                                            <span style={{fontSize:'13px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>
                                                    The amounts shown above are Co-Share Maximum amounts in effect as of 10/01/2020. Annual Co-Share Maximum amounts are subject
                                                    to adjustment from time to time. Always remember to check the Universal HealthShare Member Portal for the most current version
                                                    of the Sharing Guidelines, which may reflect changes that have been made since the date of the last copy you reviewed.
                                                    This option is only available at the time of enrollment, or at the time of annual program renewal.
                                            </span>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton  onClick={() => {this.setState({ACSMModal : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    {/*================================ smart share modal ==========================*/}
                                    <Modal size="lg" show={this.state.smartShareModel}  onHide={(event) => { this.setState({smartShareModel : false}) }} backdrop="static">
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>UHS SmartShare</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '0px'}}>
                                            {
                                                this.state.loaderShow && <Loader></Loader>
                                            }
                                            <iframe style={{ height: '430px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-SmartShare-Program-Grid.pdf'}></iframe>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton  onClick={(event) => {this.setState({smartShareModel : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    {/*================================ mail modal ==========================*/}
                                    <Modal size="md" show={this.state.mailModal}  onHide={(event) => { this.setState({mailModal : false}) }} backdrop="static" centered>
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>Email Confirmation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '15px', margin : '5px'}}>
                                            {
                                                this.state.loaderShow && <Loader></Loader>
                                            }
                                            <div style={{flexGrow:1}}>
                                                <Grid container spacing={1}>
                                                    <Grid xs={12} sm={12} md={6} lg={12} style={{marginBottom : '5px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif', fontSize : '14px'}}>
                                                        <span>Please confirm email to send quote</span>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={12}>
                                                        <Sample setChild={this.getValue.bind(this)} name={'email'} label={'Email'} reqFlag={true} value={this.state.email} disable={false} style={customStyle.textFieldWrp} length={150}  fieldType={'email'} errMsg={'Enter valid email id'} helperMsg={'Email id required'}  parentDetails={{}}></Sample>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton disabled={this.state.email === ""} onClick={(event) => this.sendQuoteMail()}>SEND QUOTE EMAIL</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    {/*================================ Enroll modal ==========================*/}
                                    <Modal size="md" show={this.state.erollModal}  onHide={(event) => { this.setState({erollModal : false}) }} backdrop="static" centered>
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>Call to Enroll</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '15px', margin : '5px'}}>                                            
                                            <div style={{flexGrow:1}}>
                                                <p>To join, simply call <b>{this.state.agentDetails && this.state.agentDetails.phone} (option 4)</b> and register with one of our trusted coaches who will walk you through the process.</p>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton onClick={(event) => { this.setState({erollModal : false}) }}>DONE</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal size="xs" show={this.state.msgModal} onHide={(event) => this.setState({msgModal:false,loaderShow : false, errMsg : ''})} backdrop="static" centered>
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>Message</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ margin: '10px',textAlign:'center',fontFamily : 'Roboto, Arial, Helvetica, sans-serif' }}>
                                            {this.state.errMsg}
                                        </Modal.Body>
                                        <Modal.Footer style={{alignItems:'right'}}>
                                            <CustomButton style={{marginTop: '10px', width: '50px', height: '40px'}} onClick={()=>{this.setState({ msgModal : false,loaderShow : false, errMsg : ''})}}>Ok</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal size="xs" show={this.state.confirmationModal} backdrop="static" centered>
                                        <Modal.Header style={customStyle.modal_header}>
                                            <Modal.Title>Message</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ margin: '10px',textAlign:'center',fontFamily : 'Roboto, Arial, Helvetica, sans-serif' }}>
                                            The quote will be emailed to <b>{this.state.email}</b>. Confirm to proceed.
                                        </Modal.Body>
                                        <Modal.Footer style={{alignItems:'right'}}>
                                            <CustomButton style={{width: '90px', height: '40px'}} onClick={()=>this.sendQuoteMail()}>Confirm</CustomButton>
                                            <CustomButton style={{marginLeft: '10px', width: '70px', height: '40px'}} onClick={()=>{this.setState({ confirmationModal : false,loaderShow : false})}}>Cancel</CustomButton>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal size="xl" show={this.state.easyShareModal}  onHide={(event) => { this.setState({easyShareModal : false}) }} backdrop="static">
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>UHS EasyShare</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '0px'}}>
                                            {
                                                this.state.loaderShow && <Loader></Loader>
                                            }
                                            <iframe style={{ height: '430px', width: '100%' }}  onLoad={()=>this.setState({loaderShow:false})}  src={'https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-EasyShare-Program-Grid.pdf'}></iframe>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton  onClick={(event) => {this.setState({easyShareModal : false})}}>{i18n.t('BUTTON.DONE')}</CustomButton>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal size="md" show={this.state.msgModal} onHide={(event) => this.setState({ msgModal: false, loaderShow: false, code: null, reAssignSuccess: false,  emailID:false, inviteStatus:"", phoneNumber:"" })} backdrop='static' centered>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '15px' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }

                        {                       
                            this.state.reAssignSuccess ? 
                            <p><b>{this.state.selecetedAgentName}</b> has been now assigned to assist <b>{this.state.prospectFullName}</b></p>:
                            null
                        }

                        <div style={customStyle.HomeContainer}>
                            <div style={customStyle.HomeMAinChild}>
                                {this.state.errMsg}

                              
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ msgModal: false, loaderShow: false, code: null, reAssignSuccess: false, emailID:false, inviteStatus:"", phoneNumber:"" })}>Close</CustomButton>
                    </Modal.Footer>
                </Modal>

                                    {/* <Modal size="lg" show={this.state.showQuickQuoteModal} onHide={() => this.closeCuram()} backdrop="static" centered>
                                        <Modal.Header style={customStyle.modal_header} closeButton>
                                            <Modal.Title>Call to Enroll</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: '10px', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', }}>
                                            <div className='form_tag'>
                                                <div>
                                                    <text>To join simply call <b>{this.state.agentDetails && this.state.agentDetails.phone} (option 4)</b> and register with one of our trusted coaches who will walk you through the process. Alternatively, please share your contact information to have a representative call you directly. We can't wait to get to know you and have you join the UHF community.</text>
                                                    <text>To join simply call <b>{this.state.agentDetails && this.state.agentDetails.phone} (option 4)</b> and register with one of our trusted coaches who will walk you through the process. Alternatively, please share your contact information to have a representative call you directly. We can't wait to get to know you and have you join the UHF community.</text>
                                                </div>
                                                <form>
                                                    <div className="row" style={{margin: '20px 0px'}}>
                                                        <div className="col-md-8">
                                                            <b>Your contact details</b>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginLeft: 20}}>
                                                        <div className="col-md-5">
                                                            <SampleName setChild={this.setUserValue.bind(this)}
                                                                reqFlag={true} name={'firstname'} label={'Your first name'}
                                                                value={this.state.fname} disable={false}
                                                                style={{ marginBottom: '20px', width: '100%' }} length={25}
                                                                fieldType={'text'} errMsg={'Enter valid first name'}
                                                                //   helperMsg={'First name required'} 
                                                                parentDetails={{ name: 'firstname' }}></SampleName>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <SampleName setChild={this.setUserValue.bind(this)} reqFlag={true}
                                                                name={'lastname'} label={'Your last name'} value={this.state.lname}
                                                                disable={false} style={{ marginBottom: '20px', width: '100%' }} length={25}
                                                                fieldType={'text'} errMsg={'Enter valid last name'}
                                                                parentDetails={{ name: 'lastname' }}></SampleName>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginLeft: 20}}>
                                                        <div className="col-md-5">
                                                            <Sample
                                                                setChild={this.setUserValue.bind(this)}
                                                                reqFlag={true} name={'email'}
                                                                label={'Your email address'} value={this.state.userEmail} disable={false}
                                                                style={{ marginBottom: '20px', width: '100%' }} length={50} fieldType={'email'}
                                                                errMsg={'Enter valid email Id'} parentDetails={{ name: 'email' }} />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <CssPhone
                                                                key={this.state.phone}
                                                                style={{width: '100%', backgroundColor: 'red !important'}}
                                                                name="phone"
                                                                id="filled-required"
                                                                label="Your phone number"
                                                                data-cy="user-phone"
                                                                variant="filled"
                                                                defaultCountry={"us"}
                                                                autoComplete='off'
                                                                value={this.state.phone}
                                                                onChange={(e) => {
                                                                    console.log('phone val - ',e);
                                                                    this.state.phone = e
                                                                }}
                                                            />
                                                            <CssPhone
                                                                key={this.state.phone}
                                                                style={{width: '100%', backgroundColor: 'red !important'}}
                                                                name="phone"
                                                                id="filled-required"
                                                                label="Your phone number"
                                                                data-cy="user-phone"
                                                                variant="filled"
                                                                defaultCountry={"us"}
                                                                autoComplete='off'
                                                                value={this.state.phone}
                                                                onChange={(e) => {
                                                                    console.log('phone val - ',e);
                                                                    this.state.phone = e
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <div className="submitBtn">
                                                <CustomButton style={{marginTop: 0}} color="primary" disabled={this.state.disableSubmit} aria-label="add" type="submit" onClick={this.submitData}>Submit</CustomButton>
                                            </div>
                                        </Modal.Footer>
                                    </Modal> */}

 

 {/* <ChatButtonNew/> */}
                                </div>
                    
                </div>
                <Grid container direction="row"
                                    justify="center"
                                    // alignItems="flex-end" 
                                    >
                                        {
                        this.state.STATE_PARAM &&
                        // <span style={{fontSize:'10px',marginRight:'17px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>CID: {this.state.STATE_PARAM.clientId}, OID:{this.state.STATE_PARAM.associationId}, BID:{this.state.STATE_PARAM.brokerId} {sessionStorage.getItem('EMP_NAME') ? <> , EID : {sessionStorage.getItem('EMP_NAME')} </> : ''}  </span>
                    <span style={{fontSize:'13px',marginBottom:'10px', fontFamily : 'Roboto, Arial, Helvetica, sans-serif'}}>{window.copyrightText()}</span>
                    }
                </Grid>
                   
            </div>
            // </div>
        )
    }

}